<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="$refs.addModal.showModal()"
              v-on:delete="deleteBucket"
              path="work::buckets"
              :methods="listOptions"
              :show-languages="false"
              :show-currencies="false"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              type="buckets"
              v-on:loadMore="loadMore"
              v-on:order="orderBy"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      ref="multipleEditModal"
      :title="`Edit ${selectedItems.length} operations`"
      :text="`<p>You will edit<b> ${selectedItems.length}</b> operations</p>
 <p>You will edit all selected rows.</p>`"
      cancel-button="Modify only edited row"
      :ok-button="`Edit selected ${selectedItems.length} rows`"
      v-on:success="multipleEdit"
    />
    <NewWebInformation
      ref="addModal"
      v-on:add="addNewRow"
      :statuses="statuses"
    />
  </div>
</template>

<script>
import DynamicTable from "@/components/lists/DynamicTable";
import TopBar from "@/components/lists/TopBar";
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import lineOptions from "@/modules/lineOptions";
import ConfirmModal from "../../components/modals/ConfirmModal";
import NewWebInformation from "../../components/modals/NewWebInformation.vue";

export default {
  name: "WebInformations",
  components: {
    NewWebInformation,
    ConfirmModal,
    DynamicTable,
    TopBar,
  },
  created() {},
  data() {
    return {
      store: useStore(),
      tableKey: 0,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      statuses: [],
      selectedItems: [],
      selectedEditMethod: {},
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      order: {
        row: null,
        desc: true,
      },
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    filters() {
      return this.store.state.filters?.webInformations;
    },
    languages() {
      return this.store.state.languages;
    },
    webshops() {
      return this.store.state.webshops;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "webInformations");
    this.loadData();

    ws.init();

    ws.subscribe("add", "web_informations", (e) => {
      if (e.session !== this.sessionId) {
        this.items.push(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", "web_informations", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (e.session !== this.sessionId) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.page = 1;
      this.order = order;
      this.loadData();
    },
    loadMore() {
      console.log("No more page yet");
      // this.page++;
      // this.loadData();
    },
    addNewRow(row) {
      row.selected = this.selectAll;
      if (this.selectAll) {
        this.selectAllDiff++;
      }
      this.items.unshift(row);
      this.tableKey++;
    },
    loadData() {
      let url = `/web/informations?page=${this.page}&order[desc]=${
        this.order.desc ? 1 : 0
      }&order[row]=${this.order.row ? this.order.row : ""}`;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          if (this.rows.length === 0) {
            let cs = data.rows.find((e) => e.row_key === "webshop_id");
            console.log(cs);
            console.log(this.webshops);
            cs.values = this.webshops;

            cs = data.rows.find((e) => e.row_key === "language_code");
            cs.values = this.languages;

            this.rows = lineOptions.init(data.rows, data.line_options);

            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0]?.id;
          }
          this.statuses = data.statuses;
          this.items = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    valUpdated(val, row, id, item) {
      if (this.selectedItems.length > 1) {
        this.$refs.multipleEditModal.showModal();
        this.selectedEditMethod = {
          val: val,
          row: row,
          id: id,
          item: item,
        };
      }
      this.editItems(val, row, id);
    },
    multipleEdit() {
      this.editItems(
        this.selectedEditMethod.val,
        this.selectedEditMethod.row,
        this.selectedItems.map((e) => e.id)
      );
    },
    editItems(val, row, id) {
      let data = {
        id: id,
      };
      data[row] = val;
      http.fetch("/web/informations/update", data, true);
    },
    deleteBucket() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http.fetch(`/work/fefe`, { rows: data }, true, "DELETE").then(() => {
        for (const item of this.selectedItems) {
          let i = this.items.indexOf(item);
          if (i > -1) {
            this.items.splice(i, 1);
          }
        }
        this.tableKey++;
      });
    },
  },
};
</script>
